var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height justify-center"},[(_vm.forms.length === 0)?_c('v-btn',{on:{"click":_vm.createForm}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create your first form ")],1):_vm._e(),(_vm.forms.length > 0)?_c('v-card',[_c('v-card-title',[_vm._v("Available forms")]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',{staticClass:"pt-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.forms,"items-per-page":20,"page":_vm.pageNumber,"loading":_vm.loading,"must-sort":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatter.fromISO(item.createDate).toFormat('dd-LL-yyyy'))+" ")]}},{key:"item.expireDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatter.fromISO(item.expireDate).toFormat('dd-LL-yyyy'))+" ")]}},{key:"item.questions",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-1 mb-1",attrs:{"dense":"","color":"primary","x-small":""}},[_vm._v(_vm._s(item.questions.length))])]}},{key:"item.open",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-1 mb-1",attrs:{"dense":"","dark":"","color":item.open ? 'primary' : 'red',"x-small":""}},[_vm._v(_vm._s(item.open ? 'Yes' : 'No'))])]}},{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(item.open === false)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openForm(item)}}},[_c('v-icon',[_vm._v("mdi-link-variant")])],1):_vm._e(),(item.open === true)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeForm(item)}}},[_c('v-icon',[_vm._v("mdi-link-variant-off")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.viewForm(item)}}},[_c('v-icon',[_vm._v("mdi-file-export-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editForm(item)}}},[_c('v-icon',[_vm._v("mdi-cog-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.clearForm(item)}}},[_c('v-icon',[_vm._v("mdi-clipboard-remove-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteForm(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"circle":"","total-visible":5,"length":Math.ceil(_vm.forms.length/20)},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }