<template>
  <v-container class="fill-height justify-center">
    <v-btn v-if="forms.length === 0" @click="createForm">
      <v-icon>mdi-plus</v-icon>Create your first form
    </v-btn>
    <v-card v-if="forms.length > 0">
      <v-card-title>Available forms</v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text class="pt-5">
        <v-data-table
          :headers="headers"
          :items="forms"
          :items-per-page="20"
          :page="pageNumber"
          :loading="loading"
          :must-sort="true"
          hide-default-footer
        >
          <template v-slot:[`item.createDate`]="{ item }">
            {{ formatter.fromISO(item.createDate).toFormat('dd-LL-yyyy') }}
          </template>
          <template v-slot:[`item.expireDate`]="{ item }">
            {{ formatter.fromISO(item.expireDate).toFormat('dd-LL-yyyy') }}
          </template>
          <template v-slot:[`item.questions`]="{ item }">
            <v-chip dense class="mr-1 mb-1" color="primary" x-small>{{item.questions.length}}</v-chip>
          </template>
          <template v-slot:[`item.open`]="{ item }">
            <v-chip dense class="mr-1 mb-1" dark :color="item.open ? 'primary' : 'red'" x-small>{{item.open ? 'Yes' : 'No'}}</v-chip>
          </template>
          <template v-slot:[`item.uuid`]="{ item }">
            <div class="d-flex justify-end">
              <v-btn icon @click="openForm(item)" v-if="item.open === false">
                <v-icon>mdi-link-variant</v-icon>
              </v-btn>
              <v-btn icon @click="closeForm(item)"  v-if="item.open === true">
                <v-icon>mdi-link-variant-off</v-icon>
              </v-btn>
              <v-btn icon @click="viewForm(item)">
                <v-icon>mdi-file-export-outline</v-icon>
              </v-btn>
              <v-btn icon @click="editForm(item)">
                <v-icon>mdi-cog-outline</v-icon>
              </v-btn>
              <v-btn icon @click="clearForm(item)">
                <v-icon>mdi-clipboard-remove-outline</v-icon>
              </v-btn>
              <v-btn icon @click="deleteForm(item)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="pageNumber" circle :total-visible="5" :length="Math.ceil(forms.length/20)"></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon'
import { findForms, deleteForm, openForm, closeForm, clearAnswers } from '@/api/backend.js'
export default {
  name: 'AdminLayout',
  data() {
    return {
      formatter: DateTime,
      loading: false,
      pageNumber: 1,
      forms: [],
      headers: [
        { align: 'left', text: 'Id', value: 'id', sortable: true, class: 'text-no-wrap' },
        { align: 'left', text: 'Name', value: 'name', sortable: true, class: 'text-no-wrap' },
        { align: 'left', text: 'Create date', value: 'createDate', sortable: true, class: 'text-no-wrap' },
        { align: 'left', text: 'Expire date', value: 'expireDate', sortable: true, class: 'text-no-wrap' },
        { align: 'left', text: 'Questions count', value: 'questions', sortable: true, class: 'text-no-wrap' },
        { align: 'left', text: 'In progress', value: 'open', sortable: true, class: 'text-no-wrap' },
        { align: 'left', text: 'Actions', value: 'uuid', sortable: true, class: 'text-no-wrap' }
      ]
    }
  },
  mounted() {
    this.loadForms()
  },
  methods: {
    loadForms() {
      this.loading = true
      findForms().then(r => {
        this.forms = r.data
      }).catch(e => this.showErrorNotification('Could not load data'))
        .then(e => { this.loading = false })
    },
    deleteForm(form) {
      this.loading = true
      deleteForm(form.id).then(r => {
        this.showSuccessNotification('Form deleted successfully')
        this.loadForms()
      }).catch(e => this.showErrorNotification('An error occured'))
        .then(e => { this.loading = false })
    },
    openForm(form) {
      this.loading = true
      openForm(form.id)
        .then(r => {
          form.open = true
          const link = this.$router.resolve({ name: 'FormView', params: { id: form.id } }).href
          return this.showSuccessDialog(`Your form can be accessed at: ${location.origin}${link}`)
        })
        .catch(e => this.showErrorNotification('An error occured'))
        .then(e => { this.loading = false })
    },
    closeForm(form) {
      this.loading = true
      closeForm(form.id).then(r => {
        form.open = false
      }).catch(e => this.showErrorNotification('An error occured'))
        .then(e => { this.loading = false })
    },
    clearForm(form) {
      this.loading = true
      clearAnswers(form.id)
        .catch(e => this.showErrorNotification('An error occured'))
        .then(e => { this.loading = false })
    },
    editForm(form) {
      this.$router.push({ name: 'FormDetails', params: { form: form } })
    },
    viewForm(form) {
      this.$router.push({ name: 'FormView', params: { id: form.id } })
    },
    createForm() {
      this.$router.push({ name: 'FormDetails' })
    },
    showFormHistory() {

    },
    logout() {
      this.$store.commit('logout')
    }
  }
}
</script>
<style lang="scss">

</style>
